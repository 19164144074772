/**
 * Custom created component files
 */
import { GNav, GNavUL, GNavList, Gh3 } from '../../styled_components';
import { NavIcons } from '../../../assets/NavIcons';

/**
 * Import third party components
 */
import styled from 'styled-components';

export const Nav = styled(GNav)``;

export const NavUL = styled(GNavUL)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavList = styled(GNavList)`
  padding: 0;
  color: var(--bg-dark-blue-grey-color);
  &.title-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  & .back-btn {
    background: none;
    border: 0;
    padding: 0;
  }
`;

export const PageTitle = styled(Gh3)`
  color: #0e4377;
  font-size: 22px;
  font-weight: bold;
`;

export const UserInfoTileWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UserIconWrapper = styled.div`
  width: 35px;
  height: 35px;
  background-color: var(--bg-dark-blue-grey-color);
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
`;

export const UserIcon = styled.div`
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
`;

export const UserName = styled.div`
  margin-left: 5px;
  color: var(--text-dark-blue-grey-color);
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

export const NavIcon = styled(NavIcons)`
  vertical-align: bottom;
`;

export const MenuUL = styled(GNavUL)`
  position: absolute;
  right: 30px;
  background-color: var(--white-color);
  border: 1px solid var(--bg-dark-blue-grey-color);
  border-radius: 5px;
  margin-top: 4px;
  padding: 2px 0;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  transition: top 0.3s ease-in-out;
  top: ${(props) => (props.toggle ? '53px' : '-115px')};
  z-index: 99;
`;

export const MenuList = styled(GNavList)`
  padding: 7px 12px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--bg-dark-blue-grey-color);
  }

  & a {
    color: var(--text-dark-blue-grey-color);
    font-size: 12px;
    font-family: Arial, sans-serif;
    font-weight: 500;
    display: block;
  }
`;

export const MenuCloseBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const MenuSpan = styled.span`
  margin-right: 10px;
`;
