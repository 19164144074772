import { notification } from 'antd';
import { Icons } from '../assets/Icons';
import swal from 'sweetalert';
import moment from 'moment';
import { commonData } from '../locale/en/CommonData';

export const GetUserIconLT = (value) => {
  let split = value.split(' ');
  let letter = split[0].charAt(0).toUpperCase() + (split[1] ? split[1].charAt(0).toUpperCase() : '');
  return letter;
};

export const organizeFiles = (data) => {
  let files = [];
  data.forEach((element) => {
    files.push({
      id: element.id,
      uid: element.file_id,
      name: element.file_name,
      file_path: element.file_path,
      custom_name: element.custom_name,
      client_access: element.client_access === 1 ? true : false,
      size: element.size,
      type: element.file_type,
      lastModified: '',
      status: 'done',
      url: '',
      download_url: element.download_url,
    });
  });
  return files;
};

export const dateFormat = (date) => {
  const [year, month, day] = date.split('-');
  const dateObj = new Date();
  dateObj.setMonth(month - 1);

  const monthName = dateObj.toLocaleString('en-US', {
    month: 'short',
  });

  return `${day} ${monthName} ${year.slice(2)}`;
};

export const ageDateCalculate = (date) => {
  if (!date) {
    return 'NA';
  } else {
    const today = new Date();
    const birthDateObj = new Date(date);
    const ageDiffMs = today - birthDateObj;
    const ageDate = new Date(ageDiffMs);
    const runningYear = Math.abs(ageDate.getUTCFullYear() - 1970);
    const runningMonth = ageDate.getUTCMonth();
    const balanceDays = ageDate.getUTCDate();

    return (
      (runningYear ? runningYear + ' yr' : '') +
      (runningMonth ? (runningYear ? ' ' : '') + runningMonth + ' mo' : '') +
      (!runningMonth && !runningYear && balanceDays ? balanceDays + ' d' : '')
    );
  }
};

export const openNotification = (message, info = false) => {
  const notificationStyle = {
    color: '#ec0e0e',
    fontFamily: 'Arial, sans-serif',
    fontWeight: '400',
    borderRadius: '7px',
  };
  const infoNotificationStyle = {
    color: '#212121',
    fontFamily: 'Arial, sans-serif',
    fontWeight: '400',
    borderRadius: '7px',
  };
  notification.open({
    description: (
      <div style={{ marginTop: '-7px', display: 'flex', gap: '5px', width: '95%' }}>
        <Icons name='Alert' width='20' height={'20'} fill={info ? '#0e4377' : '#E95959'} />
        <span style={{ fontSize: '16px' }}>{message}</span>
      </div>
    ),
    duration: 3,
    style: info ? infoNotificationStyle : notificationStyle,
  });
};

export const alertSubscriptionExpired = () => {
  swal({
    title: 'Your subscription has not yet been activated.',
    text: 'To access the application, you must subscribe to a plan.',
  });
};

export const downloadDataExcel = (exportData) => {
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.href = exportData;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  openNotification('File has been exported Successfully.', true);
};

export const convertToUTC = (datetimeString, storedOffset) => {
  const selectedDate = moment(datetimeString).unix();
  const localeOffsetValue = moment().utcOffset() * 60;
  const selectedUTCDate = selectedDate - localeOffsetValue;
  const calculateOffsetDiff = localeOffsetValue - storedOffset;
  const convertedDate = selectedUTCDate + calculateOffsetDiff;
  const formattedDate = moment.unix(convertedDate).format(commonData.date_format + ' ' + commonData.time_format);
  return formattedDate;
};

export function parseNestedJSON(str) {
  try {
    return JSON.parse(str, (_, val) => {
      if (typeof val === 'string') return parseNestedJSON(val);
      return val;
    });
  } catch (exc) {
    return str;
  }
}

export const getDayWithSuperscript = (day) => {
  switch (day) {
    case '1':
    case '21':
    case '31':
      return (
        <>
          {day}
          <sup>st</sup>
        </>
      );
    case '2':
    case '22':
      return (
        <>
          {day}
          <sup>nd</sup>
        </>
      );
    case '3':
    case '23':
      return (
        <>
          {day}
          <sup>rd</sup>
        </>
      );
    default:
      return (
        <>
          {day}
          <sup>th</sup>
        </>
      );
  }
};
export const formatDateWithSuperscript = (date, format = 'MMM YY') => {
  const day = moment(date).format('D');
  const monthYear = moment(date).format(format);

  return (
    <>
      {getDayWithSuperscript(day)} {monthYear}
    </>
  );
};
