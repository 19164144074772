import { useState, useContext, createContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { loginService, logoutService, userProfileService } from '../../apis/authService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [currentPage, setCurrentPage] = useState({
    title: '',
    backRoute: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(true);
    getUserProfile();
    // const dataInterval = setInterval(getUserProfile, 5 * 60 * 1000);
    // return () => clearInterval(dataInterval);
  }, []);

  const getUserProfile = async () => {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      await userProfileService(token.sub)
        .then((res) => {
          if (res.data.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            setUser(null);
            setIsLoggedIn(false);
          } else if (res.status === 200) {
            setUser(res.data);
            setIsLoggedIn(false);
            if (!res.data.email_verified_at) {
              navigate('/force-password');
            }
          }
        })
        .catch((err) => {
          setUser(null);
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        });
    }
  };

  const login = async (formData) => {
    setIsLoggedIn(true);
    await loginService(formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.user.role === 'sa') {
            setError({
              status: 'error',
              message: 'Email or password is incorrect.',
            });
            setIsLoggedIn(false);
          } else {
            setError(null);
            localStorage.setItem('token', res.data.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
            setUser(res.data.user);
            setIsLoggedIn(false);
          }
        }
      })
      .catch((err) => {
        let status = err.response.status;
        console.log(err.response);
        switch (status) {
          case 401:
            setError({
              status: status,
              message: 'Email or password is incorrect.',
            });
            setIsLoggedIn(false);
            break;
          case 402:
            setError({
              status: status,
              message: err.response.data.error,
            });
            setIsLoggedIn(false);
            break;
          case 422:
            setError({
              status: status,
              message: err.response.data.password,
            });
            setIsLoggedIn(false);
            break;
          default:
            setIsLoggedIn(false);
            break;
        }
      });
  };

  const logout = () => {
    logoutService()
      .then((res) => {
        if (res.status === 200) {
          setUser(null);
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      });
  };

  const resetUser = () => {
    setUser(null);
    setIsLoggedIn(false);
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        isLoggedIn,
        login,
        logout,
        getUser: getUserProfile,
        resetUser,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const useAuth = () => {
  return useContext(AuthContext);
};
