/**
 * Import third party components
 */
import styled from 'styled-components';

/**
 * Custom created component files
 */
import { Gh2 } from '../../styled_components';

export const Heading = styled(Gh2)`
  padding: 20px 5px 17px 5px;
  color: var(--white-color);
  text-align: center;
  font-weight: bold;
`;
