import { useLocation, Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { useAuth } from '.';

const ProtectedRoutes = ({ allowedRoles }) => {
  const { user } = useAuth();
  const location = useLocation();

  return allowedRoles.includes(user.role) ? (
    <Outlet />
  ) : (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
