import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Import third party components
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

axios.defaults.baseURL = process.env.REACT_APP_VETSOFT_API_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
