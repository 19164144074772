export const commonData = {
  date_format: 'YYYY-MM-DD',
  time_format: 'HH:mm:ss',
  decimal_notation: '.',
  currency: 'INR',
  tax_label: 'GST',
  iso_code: 'IN',
  country_dial_code: '+91',
};

export const ANIMALS = [
  'bird',
  'cat',
  'cow',
  'dog',
  'goat',
  'horse',
  'pig',
  'camel',
  'donkey',
  'rabbit',
  'turtle',
  'elephant',
  'bull',
  'buffalo',
  'rat',
  'sheep',
];
