/**
 * Import third party components
 */
import styled from 'styled-components';

export const AppContentArea = styled.div`
  display: flex;
  background-color: var(--bg-main-color);
  height: 100%;
  min-height: 100vh;
`;

export const ContentArea = styled.div`
  width: calc(100% - 240px);
  padding: 0 30px;

  &.toggled {
    width: 100%;
    overflow: hidden;
  }
  @media (min-width: 900px) {
    margin-left: 240px;
  }
`;

export const TopBarArea = styled.div`
  width: 100%;
  padding: 18px 0 18px 0;
`;

export const MainContentArea = styled.div`
  width: 100%;
`;
