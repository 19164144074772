import axios from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAuth } from '../components/auth_component';

const DashboardContext = createContext(null);

const DashboardProvider = ({ children }) => {
  const [dashboardDetails, setDashboardDetails] = useState(null);

  const { user } = useAuth();

  const getDashboardData = useCallback(async () => {
    if (user) {
      await axios
        .get(`dashboard/aggregation`)
        .then((res) => {
          const { storage, whatsapp } = res.data;

          let storagePercentage = ((storage?.usage / (storage?.credits + storage?.addon_credits)) * 100).toFixed(0);
          let storageRemainingCredits = storage?.credits + storage?.addon_credits - storage?.usage;

          const tempStorage = {
            total: storage?.credits + storage?.addon_credits,
            total_used: storage?.usage,
            free_credits: storage?.credits,
            used: storage?.usage,
            remaining: storageRemainingCredits,
            percentage: storagePercentage,
            addon_credits: storage?.addon_credits,
          };

          let whatsappPercentage = (
            ((whatsapp?.usage + whatsapp?.addon_usage) / (whatsapp?.credits + whatsapp?.addon_credits)) *
            100
          ).toFixed(0);
          let whatsappRemainingCredits = whatsapp?.credits - whatsapp?.usage;
          let whatsappRemainingAddon = whatsapp?.addon_credits - whatsapp?.addon_usage;

          const tempWA = {
            total: whatsapp?.credits + whatsapp?.addon_credits,
            total_used: whatsapp?.usage + whatsapp?.addon_usage,
            free_credits: whatsapp?.credits,
            used: whatsapp?.usage,
            free_credits_remaining: whatsappRemainingCredits,
            remaining: whatsappRemainingCredits + whatsappRemainingAddon,
            percentage: whatsappPercentage,
            addon_credits: whatsapp?.addon_credits,
            addon_usage: whatsapp?.addon_usage,
            add_on_remaining: whatsappRemainingAddon,
          };

          setDashboardDetails({
            ...res.data,
            whatsapp: tempWA,
            storage: tempStorage,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  useEffect(() => {
    getDashboardData();
    // const dataInterval = setInterval(getDashboardData, 5 * 60 * 1000);
    // return () => clearInterval(dataInterval);
  }, [getDashboardData]);

  return (
    <DashboardContext.Provider value={{ dashboardDetails, getDashboardData }}>{children}</DashboardContext.Provider>
  );
};

export default DashboardProvider;

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
