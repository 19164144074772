import React, { useEffect, useState } from 'react';

/**
 * Import third party components
 */
import styled from 'styled-components';

import { useWindowSize } from '../../../../utils/CustomHooks';

const ToggleWrapper = styled.div`
  position: fixed;
  top: 100px;
  left: ${($props) => ($props.resized ? '240px' : '214px')};
  padding: 8px 6px;
  cursor: pointer;
  background-color: #75c9fd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: left 0.2s ease-in-out;
  z-index: 998;

  &:hover {
    left: 240px;
  }

  &.toggled {
    left: 0;
  }
`;

const ToggleMenu = styled.div`
  & > span {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 9999px;
    background-color: black;
  }
  & > span:not(:last-child) {
    margin-bottom: 5px;
  }
  &,
  > span {
    transition: all 0.2s ease-in-out;
  }
  &.active {
    transition-delay: 0.4s;
    transform: rotate(45deg);
  }
  &.active > span:nth-child(2) {
    width: 0;
  }
  &.active > span:nth-child(1) {
    transition-delay: 0.2s;
  }
  &.active span:nth-child(3) {
    transition-delay: 0.2s;
  }
  &.active > span:nth-child(1) {
    transform: translateY(6px);
  }
  &.active > span:nth-child(3) {
    transform: translateY(-8px) rotate(90deg);
  }
`;

export default function SideNavBarToggle({ toggle, handleToggle }) {
  const [_toggle, setToggle] = useState(() => toggle);
  const [resized, setResized] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    setToggle(toggle);
  }, [toggle]);

  function __handleToggle() {
    if (!resized) {
      toggleClasses();
    }
  }
  function toggleClasses() {
    let contentwrapper = document.getElementById('content-wrapper-area');
    if (!resized && !_toggle && !contentwrapper.classList.contains('toggled'))
      contentwrapper.classList.toggle('toggled');
  }

  useEffect(() => {
    if (width < 900 && width !== 0 && !resized) {
      setToggle(true);
      setResized(true);
      toggleClasses();
    } else if (width > 900 && width !== 0 && resized) {
      setResized(false);
      setToggle(false);
      toggleClasses();
    }
  }, [width]);

  const toggleSidebar = () => {
    handleToggle();
    __handleToggle();
  };

  return (
    <ToggleWrapper className={_toggle ? 'toggled' : ''} resized={resized} onClick={toggleSidebar}>
      <ToggleMenu id='toggle-menu-btn' className={_toggle ? '' : 'active'}>
        <span></span>
        <span></span>
        <span></span>
      </ToggleMenu>
    </ToggleWrapper>
  );
}
