import { useEffect, useState } from 'react';

/**
 * Custom created component files
 */
import {
  Nav,
  NavCloseBG,
  NavContentArea,
  NavHeadingContainer,
  NavIcon,
  NavList,
  NavListTitle,
  NavUL,
  RouteLink,
  SideBar,
  SubMenu,
  SubMenuList,
  SubMenuTitle,
  SubRouteLink,
} from './side_nav_bar_data/SideNavBarStyle';
import SideNavHeading from '../SideNavHeading';
import { Icons } from '../../../assets/Icons';

/**
 * Data list
 */
import { SidebarData } from './side_nav_bar_data';
import SideNavBarToggle from './side_bar_toggle';
import {
  NavFooterContainer,
  ProgressContainer,
  ProgressHeader,
  ProgressLimit,
  ProgressStartLimit,
  ProgressEndLimit,
  ProgressBar,
  ProgressBalance,
  ProgressValue,
  ProgressSlider,
} from './side_nav_bar_data/SideNavBarStyle';
import { useWindowSize } from '../../../utils/CustomHooks';
import { NavLink, useLocation } from 'react-router-dom';
import { FormatBytes } from '../../../utils/FileTypes';
import { useAuth } from '../../auth_component';
import { useDashboardContext } from '../../../context/DashboardContext';

/**
 * @returns Nav bar sub menu
 */
export const SubMenuWrapper = ({
  data,
  listIndex,
  currentPage,
  currentPath,
  handlePageLink,
  activeSubMenu,
  setActiveSubMenu,
}) => {
  const [toggle, setToggle] = useState(() => {
    return currentPage === data.path ? true : false;
  });
  useEffect(() => {
    if (currentPage === data.path) {
      setActiveSubMenu(listIndex);
    }
  }, []);

  const handleSubMenu = (e) => {
    setToggle(!toggle);
    setActiveSubMenu(listIndex);
  };

  const handleSubLink = (value) => {
    handlePageLink(value);
  };

  return (
    <SubMenu>
      <SubMenuTitle
        className={currentPage === data.path ? 'active' : ''}
        id={`${data.title}_${listIndex}`}
        onClick={(e) => handleSubMenu(e)}
      >
        <div>
          <NavIcon name={data.icon} width={20} height={20} />
          <NavListTitle>{data.title}</NavListTitle>
        </div>
        <div className={listIndex === activeSubMenu || currentPage === data.path ? 'arrow-down' : ''}>
          <NavIcon name={'DropArrow'} width={13} height={20} fill='#839aa8' />
        </div>
      </SubMenuTitle>
      <SubMenuList
        key={`sub-list_${listIndex}`}
        className={listIndex === activeSubMenu || currentPage === data.path ? 'toggled' : ''}
      >
        {data.subList.map((subElement, index) => (
          <SubRouteLink
            key={`${subElement.title}_${index}`}
            to={subElement.path}
            onClick={handleSubLink}
            className={subElement.supportPath.includes(currentPath) ? 'selected' : ''}
          >
            <NavIcon name={subElement.icon} width={20} height={20} />
            <NavListTitle>{subElement.title}</NavListTitle>
          </SubRouteLink>
        ))}
      </SubMenuList>
    </SubMenu>
  );
};

export default function SideNavBar() {
  const auth = useAuth();
  const { dashboardDetails } = useDashboardContext();
  const getPath = () => {
    let split = window.location.pathname.split('/');
    return split[1];
  };

  const [currentPage, setCurrentPage] = useState(() => getPath());
  const [toggle, setToggle] = useState(false);
  const [resized, setResized] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [width] = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(getPath());
  }, [location.pathname]);

  const handlePageLink = () => {
    setCurrentPage(getPath());
  };
  const handleGroupPageLink = () => {
    setCurrentPage(getPath());
    setActiveSubMenu(null);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleSubMenuToggle = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  useEffect(() => {
    if (width < 900 && width !== 0 && !resized) {
      setToggle(true);
      setResized(true);
    } else if (width > 900 && width !== 0 && resized) {
      setResized(false);
      setToggle(false);
    }
  }, [width, resized]);

  return (
    <SideBar className={toggle ? 'toggled' : ''}>
      <NavCloseBG
        id='side-nav-bar-close-bg'
        onClick={handleToggle}
        className={toggle ? 'toggled' : ''}
        resized={resized}
      />
      <NavContentArea id='side-nav-bar' className={toggle ? 'toggled' : ''} toggle={toggle} resized={resized}>
        <NavHeadingContainer>
          <SideNavHeading user={auth && auth.user} />
        </NavHeadingContainer>
        <Nav>
          <NavUL>
            {SidebarData[auth?.user.role].map((element, index) => {
              return element.subList.length !== 0 ? (
                <NavList key={`${element.title}_${index}`}>
                  <SubMenuWrapper
                    currentPage={currentPage}
                    data={element}
                    listIndex={index}
                    handlePageLink={handlePageLink}
                    currentPath={location.pathname}
                    activeSubMenu={activeSubMenu}
                    setActiveSubMenu={handleSubMenuToggle}
                  />
                </NavList>
              ) : (
                <NavList key={`${element}_${index}`} onClick={handleGroupPageLink}>
                  <RouteLink
                    className={element.supportPath.includes(location.pathname) ? 'selected' : ''}
                    to={element.path}
                  >
                    <NavIcon name={element.icon} width={20} height={20} />
                    <NavListTitle>{element.title}</NavListTitle>
                  </RouteLink>
                </NavList>
              );
            })}
          </NavUL>
        </Nav>
        <NavFooterContainer>
          <NavLink to='/my-clinic/wacredits'>
            <ProgressContainer className='whatsapp-credit'>
              <div>
                <Icons name='Whatsapp' width={30} height={30} fill='#fff' />
              </div>
              <div>
                <ProgressHeader>WhatsApp</ProgressHeader>
                {/* <ProgressLimit>
                  <ProgressStartLimit>{dashboardDetails?.whatsapp.total_used}</ProgressStartLimit>
                  <ProgressEndLimit>{dashboardDetails?.whatsapp.total}</ProgressEndLimit>
                </ProgressLimit>
                <ProgressBar usagepercent={dashboardDetails?.whatsapp.percentage}>
                  <ProgressValue>{dashboardDetails?.whatsapp.percentage}%</ProgressValue>
                  <ProgressSlider value={`${dashboardDetails?.whatsapp.percentage}%`}></ProgressSlider>
                </ProgressBar> */}
                <ProgressBalance>
                  <b>{dashboardDetails?.whatsapp.remaining}</b> credits left
                </ProgressBalance>
              </div>
            </ProgressContainer>
          </NavLink>

          <NavLink to='/my-clinic/sgcredits'>
            <ProgressContainer>
              <ProgressHeader>Storage</ProgressHeader>
              <ProgressLimit>
                <ProgressStartLimit>{FormatBytes(dashboardDetails?.storage.total_used)}</ProgressStartLimit>
                <ProgressEndLimit>{FormatBytes(dashboardDetails?.storage.total)}</ProgressEndLimit>
              </ProgressLimit>
              <ProgressBar usagepercent={dashboardDetails?.storage.percentage}>
                <ProgressValue>{dashboardDetails?.storage.percentage}%</ProgressValue>
                <ProgressSlider value={`${dashboardDetails?.storage.percentage}%`}></ProgressSlider>
              </ProgressBar>
              <ProgressBalance>{FormatBytes(dashboardDetails?.storage.remaining)}&nbsp;left</ProgressBalance>
            </ProgressContainer>
          </NavLink>
        </NavFooterContainer>
      </NavContentArea>
      <SideNavBarToggle resized={resized} toggle={toggle} handleToggle={handleToggle} />
    </SideBar>
  );
}
