import Word from '../assets/images/ICONS/Word.png';
import Excel from '../assets/images/ICONS/Excel.png';
import PDF from '../assets/images/ICONS/PDF.png';
import TXT from '../assets/images/ICONS/TXT.png';
import Image from '../assets/images/ICONS/Image.png';

export const FileTypeCategory = {
  Word: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
  ],
  Excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  PDF: ['application/pdf'],
  Image: ['image/svg+xml', 'image/jpeg', 'image/x-citrix-jpeg', 'image/x-png'],
  TXT: ['text/plain'],
};

export const FileTypeBasedIcon = {
  Word: Word,
  Excel: Excel,
  PDF: PDF,
  Image: Image,
  TXT: TXT,
};

export const FormatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
