/**
 * Import third party components
 */
import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const bounce = keyframes`
    from{
        transform: scaleX(1.2s);
    }
    to{
        transform: translateY(-15px) scaleX(1);
    }
`;

const LoaderSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // inset: 0;
`;
const LoaderWrapper = styled.div`
  max-width: 75px;
  width: 100%;
  max-height: 75px;
  min-height: 25px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
`;

const LoaderBall = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : '#0e4377')};
  animation: ${bounce} 0.5s alternate infinite;

  &:nth-child(2) {
    animation-delay: 0.16s;
  }
  &:nth-child(3) {
    animation-delay: 0.32s;
  }
`;

export default function Loader(props) {
  return (
    <LoaderSection>
      <LoaderWrapper>
        <LoaderBall {...props} />
        <LoaderBall {...props} />
        <LoaderBall {...props} />
      </LoaderWrapper>
    </LoaderSection>
  );
}
