export const SidebarData = {
  admin_doctor: [
    {
      title: 'Pets',
      path: '/',
      icon: 'NavPet',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/', '/pet-details'],
    },
    {
      title: 'Pet Owners',
      path: '/pet-owners/list',
      icon: 'NavUser',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/pet-owners/list', '/pet-owners/details'],
    },
    {
      title: 'Visits',
      path: '/visits',
      icon: 'NavPaw',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/visits'],
    },
    {
      title: 'Appointments',
      path: '/appointment',
      icon: 'NavAppointment',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/appointment'],
    },
    {
      title: 'Billing',
      path: 'billing',
      icon: 'NavTemplate',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Bills',
          path: '/billing',
          icon: 'NavBill',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing'],
        },
        {
          title: 'Bill Returns',
          path: '/billing/bill-returns',
          icon: 'BillReturn',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/bill-returns'],
        },
        {
          title: 'Products/Services',
          path: '/billing/services',
          icon: 'ProductServices',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/services'],
        },
        {
          title: 'Purchases',
          path: '/billing/purchases',
          icon: 'Purchase',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/purchases'],
        },
        {
          title: 'Purchase Returns',
          path: '/billing/purchase-returns',
          icon: 'PurchaseReturn',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/purchase-returns'],
        },
        {
          title: 'Vendors',
          path: '/billing/vendors',
          icon: 'Vendor',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/vendors'],
        },
        {
          title: 'Tax Classes',
          path: '/billing/tax-classes',
          icon: 'TaxClasses',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/tax-classes'],
        },
        {
          title: 'Payment Modes',
          path: '/billing/payment-modes',
          icon: 'NavBill',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/payment-modes'],
        },
      ],
    },
    {
      title: 'Templates',
      path: 'template',
      icon: 'NavTemplate',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Prescriptions',
          path: '/template/prescription-templates',
          icon: 'Prescriptions',
          cName: 'nav-sub-list-text',
          supportPath: ['/template/prescription-templates', '/template/prescription-template'],
        },
        {
          title: 'Prescription Pad',
          path: '/template/letterpad',
          icon: 'LetterPad',
          cName: 'nav-sub-list-text',
          supportPath: ['/template/letterpad', '/template/letterpad-template'],
        },
        {
          title: 'Bill Pad',
          path: '/template/billpad',
          icon: 'BillPad',
          cName: 'nav-sub-list-text',
          supportPath: ['/template/billpad', '/template/billpad-template'],
        },
      ],
    },
    {
      title: 'Masters',
      path: 'master',
      icon: 'NavLayer',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Animals',
          path: '/master/animal',
          icon: 'Animal',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/animal'],
        },
        {
          title: 'Breeds',
          path: '/master/breed',
          icon: 'Breed',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/breed'],
        },
        {
          title: 'Colors',
          path: '/master/color',
          icon: 'Color',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/color'],
        },
        {
          title: 'Diseases',
          path: '/master/disease',
          icon: 'Disease',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/disease'],
        },
        {
          title: 'Symptoms',
          path: '/master/symptom',
          icon: 'Symptom',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/symptom'],
        },
        {
          title: 'Visit Types',
          path: '/master/visit-type',
          icon: 'VisitType',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/visit-type'],
        },
        {
          title: 'Visit Purposes',
          path: '/master/visit-purpose',
          icon: 'VisitPurpose',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/visit-purpose'],
        },
        /*  {
          title: 'SPA Services',
          path: '/master/spa-services',
          icon: 'Service',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/spa-services'],
        },
        {
          title: 'Medicines',
          path: '/master/medicine',
          icon: 'Medicine',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/medicine'],
        }, */
      ],
    },
    {
      title: 'My Clinic',
      path: 'my-clinic',
      icon: 'NavClinic',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Branches',
          path: '/my-clinic/clinics',
          icon: 'NavClinic',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/clinics'],
        },
        {
          title: 'Users',
          path: '/my-clinic/users',
          icon: 'NavMultiUser',
          cName: 'nav-list-text',
          supportPath: ['/my-clinic/users'],
        },
        {
          title: 'Subscription',
          path: '/my-clinic/pricing',
          icon: 'Subscription',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/pricing', '/my-clinic/payment'],
        },
        {
          title: 'WhatsApp Credits',
          path: '/my-clinic/wacredits',
          icon: 'WhatsAppCredits',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/wacredits', '/my-clinic/wacredits_pay'],
        },
        {
          title: 'Storage',
          path: '/my-clinic/sgcredits',
          icon: 'StorageCredits',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/sgcredits', '/my-clinic/storage'],
        },
        {
          title: 'Payment Methods',
          path: '/my-clinic/billing-profile',
          icon: 'Bill',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/billing-profile'],
        },
        {
          title: 'Invoices',
          path: '/my-clinic/invoice',
          icon: 'Invoice',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/invoice'],
        },
      ],
    },
  ],
  doctor: [
    {
      title: 'Pets',
      path: '/',
      icon: 'NavPet',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/', '/pet-details'],
    },
    {
      title: 'Pet Owners',
      path: '/pet-owners/list',
      icon: 'NavUser',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/pet-owners/list', '/pet-owners/details'],
    },
    {
      title: 'Visits',
      path: '/visits',
      icon: 'NavPaw',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/visits'],
    },
    {
      title: 'Appointments',
      path: '/appointment',
      icon: 'NavAppointment',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/appointment'],
    },
    {
      title: 'Billing',
      path: 'billing',
      icon: 'NavTemplate',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Bills',
          path: '/billing',
          icon: 'NavBill',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing'],
        },
        {
          title: 'Bill Returns',
          path: '/billing/bill-returns',
          icon: 'BillReturn',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/bill-returns'],
        },
        {
          title: 'Products/Services',
          path: '/billing/services',
          icon: 'ProductServices',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/services'],
        },
        {
          title: 'Purchases',
          path: '/billing/purchases',
          icon: 'Purchase',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/purchases'],
        },
        {
          title: 'Purchase Returns',
          path: '/billing/purchase-returns',
          icon: 'PurchaseReturn',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/purchase-returns'],
        },
        {
          title: 'Vendors',
          path: '/billing/vendors',
          icon: 'Vendor',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/vendors'],
        },
        {
          title: 'Tax Classes',
          path: '/billing/tax-classes',
          icon: 'TaxClasses',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/tax-classes'],
        },
        {
          title: 'Payment Modes',
          path: '/billing/payment-modes',
          icon: 'NavBill',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/payment-modes'],
        },
      ],
    },
    {
      title: 'Templates',
      path: 'template',
      icon: 'NavTemplate',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Prescriptions',
          path: '/template/prescription-templates',
          icon: 'Prescriptions',
          cName: 'nav-sub-list-text',
          supportPath: ['/template/prescription-templates', '/template/prescription-template'],
        },
        {
          title: 'Prescription Pad',
          path: '/template/letterpad',
          icon: 'LetterPad',
          cName: 'nav-sub-list-text',
          supportPath: ['/template/letterpad', '/template/letterpad-template'],
        },
        {
          title: 'Bill Pad',
          path: '/template/billpad',
          icon: 'BillPad',
          cName: 'nav-sub-list-text',
          supportPath: ['/template/billpad', '/template/billpad-template'],
        },
      ],
    },
    {
      title: 'Masters',
      path: 'master',
      icon: 'NavLayer',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Animals',
          path: '/master/animal',
          icon: 'Animal',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/animal'],
        },
        {
          title: 'Breeds',
          path: '/master/breed',
          icon: 'Breed',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/breed'],
        },
        {
          title: 'Colors',
          path: '/master/color',
          icon: 'Color',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/color'],
        },
        {
          title: 'Diseases',
          path: '/master/disease',
          icon: 'Disease',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/disease'],
        },
        {
          title: 'Symptoms',
          path: '/master/symptom',
          icon: 'Symptom',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/symptom'],
        },
        {
          title: 'Visit Types',
          path: '/master/visit-type',
          icon: 'VisitType',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/visit-type'],
        },
        {
          title: 'Visit Purposes',
          path: '/master/visit-purpose',
          icon: 'VisitPurpose',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/visit-purpose'],
        },
        /*  {
          title: 'SPA Services',
          path: '/master/spa-services',
          icon: 'Service',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/spa-services'],
        },
        {
          title: 'Medicines',
          path: '/master/medicine',
          icon: 'Medicine',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/medicine'],
        }, */
      ],
    },
    {
      title: 'My Clinic',
      path: 'my-clinic',
      icon: 'NavClinic',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Branches',
          path: '/my-clinic/clinics',
          icon: 'NavClinic',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/clinics'],
        },
        {
          title: 'Users',
          path: '/my-clinic/users',
          icon: 'NavMultiUser',
          cName: 'nav-list-text',
          supportPath: ['/my-clinic/users'],
        },
        {
          title: 'Subscription',
          path: '/my-clinic/pricing',
          icon: 'Subscription',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/pricing', '/my-clinic/payment'],
        },
        {
          title: 'WhatsApp Credits',
          path: '/my-clinic/wacredits',
          icon: 'WhatsAppCredits',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/wacredits', '/my-clinic/wacredits_pay'],
        },
        {
          title: 'Storage',
          path: '/my-clinic/sgcredits',
          icon: 'StorageCredits',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/sgcredits', '/my-clinic/storage'],
        },
        {
          title: 'Payment Methods',
          path: '/my-clinic/billing-profile',
          icon: 'Bill',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/billing-profile'],
        },
        {
          title: 'Invoices',
          path: '/my-clinic/invoice',
          icon: 'Invoice',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/invoice'],
        },
      ],
    },
  ],
  assistant: [
    {
      title: 'Pets',
      path: '/',
      icon: 'NavPet',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/', '/pet-details'],
    },
    {
      title: 'Pet Owners',
      path: '/pet-owners/list',
      icon: 'NavUser',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/pet-owners/list', '/pet-owners/details'],
    },
    {
      title: 'Visits',
      path: '/visits',
      icon: 'NavPaw',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/visits'],
    },
    {
      title: 'Appointments',
      path: '/appointment',
      icon: 'NavAppointment',
      cName: 'nav-list-text',
      subList: [],
      supportPath: ['/appointment'],
    },
    {
      title: 'Billing',
      path: 'billing',
      icon: 'NavTemplate',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Bills',
          path: '/billing',
          icon: 'NavBill',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing'],
        },
        {
          title: 'Bill Returns',
          path: '/billing/bill-returns',
          icon: 'BillReturn',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/bill-returns'],
        },
        {
          title: 'Products/Services',
          path: '/billing/services',
          icon: 'ProductServices',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/services'],
        },
        {
          title: 'Purchases',
          path: '/billing/purchases',
          icon: 'Purchase',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/purchases'],
        },
        {
          title: 'Purchase Returns',
          path: '/billing/purchase-returns',
          icon: 'PurchaseReturn',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/purchase-returns'],
        },
        {
          title: 'Vendors',
          path: '/billing/vendors',
          icon: 'Vendor',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/vendors'],
        },
        {
          title: 'Tax Classes',
          path: '/billing/tax-classes',
          icon: 'TaxClasses',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/tax-classes'],
        },
        {
          title: 'Payment Modes',
          path: '/billing/payment-modes',
          icon: 'NavBill',
          cName: 'nav-sub-list-text',
          supportPath: ['/billing/payment-modes'],
        },
      ],
    },
    {
      title: 'Masters',
      path: 'master',
      icon: 'NavLayer',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Animals',
          path: '/master/animal',
          icon: 'Animal',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/animal'],
        },
        {
          title: 'Breeds',
          path: '/master/breed',
          icon: 'Breed',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/breed'],
        },
        {
          title: 'Colors',
          path: '/master/color',
          icon: 'Color',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/color'],
        },
        {
          title: 'Diseases',
          path: '/master/disease',
          icon: 'Disease',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/disease'],
        },
        {
          title: 'Symptoms',
          path: '/master/symptom',
          icon: 'Symptom',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/symptom'],
        },
        {
          title: 'Visit Types',
          path: '/master/visit-type',
          icon: 'VisitType',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/visit-type'],
        },
        {
          title: 'Visit Purposes',
          path: '/master/visit-purpose',
          icon: 'VisitPurpose',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/visit-purpose'],
        },
        /*  {
          title: 'SPA Services',
          path: '/master/spa-services',
          icon: 'Service',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/spa-services'],
        },
        {
          title: 'Medicines',
          path: '/master/medicine',
          icon: 'Medicine',
          cName: 'nav-sub-list-text',
          supportPath: ['/master/medicine'],
        }, */
      ],
    },
    {
      title: 'My Clinic',
      path: 'my-clinic',
      icon: 'NavClinic',
      cName: 'nav-list-text',
      subList: [
        {
          title: 'Subscription',
          path: '/my-clinic/pricing',
          icon: 'Subscription',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/pricing', '/my-clinic/payment'],
        },
        {
          title: 'WhatsApp Credits',
          path: '/my-clinic/wacredits',
          icon: 'WhatsAppCredits',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/wacredits', '/my-clinic/wacredits_pay'],
        },
        {
          title: 'Storage',
          path: '/my-clinic/sgcredits',
          icon: 'StorageCredits',
          cName: 'nav-sub-list-text',
          supportPath: ['/my-clinic/sgcredits', '/my-clinic/storage'],
        },
      ],
    },
  ],
};
