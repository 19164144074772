import { ReactComponent as NavClinic } from './clinic.svg';
import { ReactComponent as NavLayer } from './layer.svg';
import { ReactComponent as NavMessage } from './message.svg';
import { ReactComponent as NavMultiUser } from './multi-user.svg';
import { ReactComponent as NavPaw } from './paw.svg';
import { ReactComponent as NavPet } from './pet.svg';
import { ReactComponent as NavTemplate } from './template.svg';
import { ReactComponent as NavUser } from './user.svg';
import { ReactComponent as NavAppointment } from './appointment.svg';
import { ReactComponent as HamburgerMenu } from './hamburger-menu.svg';
import { ReactComponent as DropArrow } from './down-arrow-nav.svg';
import { ReactComponent as LogOut } from './logout.svg';
import { ReactComponent as Animal } from './Animal.svg';
import { ReactComponent as Breed } from './Breed.svg';
import { ReactComponent as Color } from './Color.svg';
import { ReactComponent as Disease } from './Disease.svg';
import { ReactComponent as LetterPad } from './Prescription-Pad.svg';
import { ReactComponent as BillPad } from './Bill-Pad.svg';
import { ReactComponent as Medicine } from './Medicine.svg';
import { ReactComponent as Prescriptions } from './Prescriptions.svg';
import { ReactComponent as Symptom } from './Symptom.svg';
import { ReactComponent as VisitPurpose } from './VisitPurpose.svg';
import { ReactComponent as VisitType } from './VisitType.svg';
import { ReactComponent as Bill } from './Bill.svg';
import { ReactComponent as Invoice } from './invoice.svg';
import { ReactComponent as StorageCredits } from './storage-credits.svg';
import { ReactComponent as WhatsAppCredits } from './whatsapp-credits.svg';
import { ReactComponent as Subscription } from './subscription.svg';
import { ReactComponent as Service } from './spa_service.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as NavBill } from './billing.svg';
import { ReactComponent as TaxClasses } from './Tax-Classes.svg';
import { ReactComponent as ProductServices } from './Products-Services.svg';
import { ReactComponent as BillReturn } from './Bill-Returns.svg';
import { ReactComponent as Purchase } from './Purchases.svg';
import { ReactComponent as PurchaseReturn } from './Purchase-Returns.svg';
import { ReactComponent as Vendor } from './Vendors.svg';

export const NavIcons = ({ name, width = 20, height = 20, fill, stroke }) => {
  switch (name) {
    case 'NavClinic':
      return <NavClinic width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavLayer':
      return <NavLayer width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavMessage':
      return <NavMessage width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavMultiUser':
      return <NavMultiUser width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavPaw':
      return <NavPaw width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavPet':
      return <NavPet width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavTemplate':
      return <NavTemplate width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavUser':
      return <NavUser width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavAppointment':
      return <NavAppointment width={width} height={height} fill={fill} stroke={stroke} />;
    case 'HamburgerMenu':
      return <HamburgerMenu width={width} height={height} fill={fill} stroke={stroke} />;
    case 'DropArrow':
      return <DropArrow width={width} height={height} fill={fill} stroke={stroke} />;
    case 'LogOut':
      return <LogOut width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Animal':
      return <Animal width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Breed':
      return <Breed width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Color':
      return <Color width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Disease':
      return <Disease width={width} height={height} fill={fill} stroke={stroke} />;
    case 'LetterPad':
      return <LetterPad width={width} height={height} fill={fill} stroke={stroke} />;
    case 'BillPad':
      return <BillPad width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Medicine':
      return <Medicine width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Prescriptions':
      return <Prescriptions width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Symptom':
      return <Symptom width={width} height={height} fill={fill} stroke={stroke} />;
    case 'VisitPurpose':
      return <VisitPurpose width={width} height={height} fill={fill} stroke={stroke} />;
    case 'VisitType':
      return <VisitType width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Bill':
      return <Bill width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Invoice':
      return <Invoice width={width} height={height} fill={fill} stroke={stroke} />;
    case 'WhatsAppCredits':
      return <WhatsAppCredits width={width} height={height} fill={fill} stroke={stroke} />;
    case 'StorageCredits':
      return <StorageCredits width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Subscription':
      return <Subscription width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Service':
      return <Service width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Support':
      return <Support width={width} height={height} fill={fill} stroke={stroke} />;
    case 'NavBill':
      return <NavBill width={width} height={height} fill={fill} stroke={stroke} />;
    case 'TaxClasses':
      return <TaxClasses width={width} height={height} fill={fill} stroke={stroke} />;
    case 'ProductServices':
      return <ProductServices width={width} height={height} fill={fill} stroke={stroke} />;
    case 'BillReturn':
      return <BillReturn width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Purchase':
      return <Purchase width={width} height={height} fill={fill} stroke={stroke} />;
    case 'PurchaseReturn':
      return <PurchaseReturn width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Vendor':
      return <Vendor width={width} height={height} fill={fill} stroke={stroke} />;

    default:
      return <NavLayer width={width} height={height} fill={fill} stroke={stroke} />;
  }
};
