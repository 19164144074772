/**
 * Import third party components
 */
import styled from 'styled-components';

export const GNav = styled.nav``;
export const GNavUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const GNavList = styled.li`
  padding: 10px 5px;
`;
export const Gh1 = styled.h1``;
export const Gh2 = styled.h2`
  font-size: 26px;
  margin: 0;
`;
export const Gh3 = styled.h3`
  font-size: 22px;
  margin: 0;
`;
export const Gh4 = styled.h4``;
export const Gh5 = styled.h5``;
export const Gh6 = styled.h6``;
export const Gp = styled.p`
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  margin: 0;
`;
export const Gspan = styled.span`
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: 500;
  margin: 0;
`;
export const GAnchor = styled.a`
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: 500;
  color: var(--text-dark-blue-color);
`;

export const GFlexWrapper = styled.div`
  display: flex;
  // align-items: center;
  gap: 16px;

  @media (max-width: 576px) {
    & {
      flex-direction: column;
      align-items: normal !important;
      gap: 0;
    }
  }
`;

export const GFlexColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GFirstColWrapper = styled.div`
  width: 100%;
`;

export const ActiveSwitch = styled.div`
  display: flex;
`;

export const ActiveSwitchbtn = styled.div`
  margin-right: 28px;
  width: 100%;
  flex-direction: row;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const BreadCrumb = styled.div`
  border-bottom: ${(props) => (props.type === 'horizontal' ? '2px solid var(--bg-dark-blue-color)' : '0')};
  background: ${(props) => (props.type === 'vertical' ? 'var(--bg-dark-blue-color)' : 'unset')};
  height: ${(props) => (props.type === 'vertical' ? '24px' : '0')};
  width: ${(props) => (props.type === 'horizontal' ? '100%' : '3px')};
`;

export const SubHeadingForm = styled.div`
  // margin: 20px 0;
  margin-bottom: 8px;

  & > span {
    color: var(--text-dark-blue-color);
    font-weight: bold;
    font-size: 18px;
  }
  & .age-text {
    text-transform: none;
    color: var(--text-dark-blue-grey-color);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .pet-owner-details > span {
    color: var(--text-dark-blue-color);
    font-weight: bold;
    font-size: 18px;
  }

  & .heading {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  & .heading .seperator {
    margin-right: 15px;
  }

  & .heading .animal-breed {
    font-size: 14px;
    font-weight: 700;
    color: var(--bg-dark-blue-color);
  }
`;

// For Antd form
export const PetDetails = styled.div`
  text-transform: capitalize;
  margin-top: 4px;
  color: var(--text-dark-blue-color);
  font-family: Arial, sans-serif;
`;

export const PetDetailsWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  & .pet-id-container {
    position: absolute;
    right: 0;
    display: flex;
    gap: 4px;
  }
  & label {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 700 !important;
    // color: var(--text-black-grey-color);
    color: gray;
  }
  & .label-thinner {
    color: var(--text-dark-green-color);
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (max-width: 576px) {
    & {
      // margin-bottom: 0;
    }
  }
`;

// Pet Id prefix
export const PetIdInputWrapper = styled.div`
  & .label-wrapper {
    padding: 0 0 8px;
  }
  & label {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 700 !important;
    color: var(--text-black-grey-color);
  }
  & .label-thinner {
    color: grey;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
  }
  & .input-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  & .notes {
    opacity: 0.6;
    margin-top: 5px;
    font-size: 14px;
  }
  & .egWrapper {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-dark-blue-grey-color);
    font-family: sans-serif;
  }
`;
