import axios from 'axios';

export const loginService = async (request_data) => {
  return await axios({
    method: 'POST',
    url: `/auth/login`,
    data: request_data,
  });
};

export const userProfileService = async (request_data) => {
  return await axios({
    method: 'GET',
    url: `/user/${request_data}`,
  });
};

export const logoutService = async () => {
  return await axios({
    method: 'POST',
    url: `/auth/logout`,
  });
};
