import { ReactComponent as Layer } from './layer.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Alert } from './alert.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as EyeSlashed } from './eye_slashed.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as View } from './view.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as GreyBGMinus } from './grey-bg-minus.svg';
import { ReactComponent as Birthday } from './birthday.svg';
import { ReactComponent as Envelope } from './envelope.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Whatsapp } from './whatsapp.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Document } from './document.svg';
import { ReactComponent as Download } from './arrow_download.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as Reset } from './reset.svg';
import { ReactComponent as PharmacyRX } from './pharmacy_rx_symbol.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Print } from './print.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as Image } from './image.svg';
import { ReactComponent as Video } from './video.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Consultation } from './consultation.svg';
import { ReactComponent as Service } from './spa_service.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as Male } from './male.svg';
import { ReactComponent as Female } from './female.svg';
import { ReactComponent as ToggleGrid } from './toggle-grid.svg';
import { ReactComponent as ToggleList } from './toggle-list.svg';

// Animal Icons
import { ReactComponent as Pets } from './animals/paw.svg';
import { ReactComponent as Cat } from './animals/Cat.svg';
import { ReactComponent as Dog } from './animals/Dog.svg';
import { ReactComponent as Goat } from './animals/Goat.svg';
import { ReactComponent as Horse } from './animals/Horse.svg';
import { ReactComponent as Pig } from './animals/Pig.svg';
import { ReactComponent as Cow } from './animals/Cow.svg';
import { ReactComponent as Bird } from './animals/Bird.svg';
import { ReactComponent as Rabbit } from './animals/rabbit.svg';
import { ReactComponent as Turtle } from './animals/Turtle.svg';
import { ReactComponent as Camel } from './animals/Camel.svg';
import { ReactComponent as Donkey } from './animals/Donkey.svg';
import { ReactComponent as Bull } from './animals/Bull.svg';
import { ReactComponent as Elephant } from './animals/elephant.svg';
import { ReactComponent as Buffalo } from './animals/Buffalo.svg';
import { ReactComponent as Rat } from './animals/Rat.svg';
import { ReactComponent as Sheep } from './animals/sheep.svg';

export const Icons = ({ name, width = 20, height = 20, fill, stroke }) => {
  switch (name) {
    case 'Close':
      return <Close width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Alert':
      return <Alert width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Eye':
      return <Eye width={width} height={height} fill={fill} stroke={stroke} />;
    case 'EyeSlashed':
      return <EyeSlashed width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Edit':
      return <Edit width={width} height={height} fill={fill} stroke={stroke} />;
    case 'View':
      return <View width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Delete':
      return <Delete width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Back':
      return <Back width={width} height={height} fill={fill} stroke={stroke} />;
    case 'plus':
      return <Plus width={width} height={height} fill={fill} stroke={stroke} />;
    case 'filter':
      return <Filter width={width} height={height} fill={fill} stroke={stroke} />;
    case 'search':
      return <Search width={width} height={height} fill={fill} stroke={stroke} />;
    case 'GreyBGMinus':
      return <GreyBGMinus width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Birthday':
      return <Birthday width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Envelope':
      return <Envelope width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Phone':
      return <Phone width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Whatsapp':
      return <Whatsapp width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Upload':
      return <Upload width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Document':
      return <Document width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Download':
      return <Download width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Save':
      return <Save width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Reset':
      return <Reset width={width} height={height} fill={fill} stroke={stroke} />;
    case 'PharmacyRX':
      return <PharmacyRX width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Calendar':
      return <Calendar width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Print':
      return <Print width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Checkmark':
      return <Checkmark width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Video':
      return <Video width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Image':
      return <Image width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Error':
      return <Error width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Consultation':
      return <Consultation width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Service':
      return <Service width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Support':
      return <Support width={width} height={height} fill={fill} stroke={stroke} />;
    case 'Export':
      return <Export width={width} height={height} fill={fill} stroke={stroke} />;
    case 'male':
      return <Male width={width} height={height} fill={fill} stroke={stroke} />;
    case 'female':
      return <Female width={width} height={height} fill={fill} stroke={stroke} />;
    case 'ToggleGrid':
      return <ToggleGrid width={width} height={height} fill={fill} stroke={stroke} />;
    case 'ToggleList':
      return <ToggleList width={width} height={height} fill={fill} stroke={stroke} />;
    case 'minus':
      return <Minus width={width} height={height} fill={fill} stroke={stroke} />;

    // Animals
    case 'cat':
      return <Cat width={width} height={height} fill={fill} stroke={stroke} />;
    case 'dog':
      return <Dog width={width} height={height} fill={fill} stroke={stroke} />;
    case 'goat':
      return <Goat width={width} height={height} fill={fill} stroke={stroke} />;
    case 'horse':
      return <Horse width={width} height={height} fill={fill} stroke={stroke} />;
    case 'pig':
      return <Pig width={width} height={height - height / 3} fill={fill} stroke={stroke} />;
    case 'cow':
      return <Cow width={width} height={height - height / 3} fill={fill} stroke={stroke} />;
    case 'bird':
      return <Bird width={width} height={height} fill={fill} stroke={stroke} />;
    case 'camel':
      return <Camel width={width} height={height} fill={fill} stroke={stroke} />;
    case 'donkey':
      return <Donkey width={width} height={height} fill={fill} stroke={stroke} />;
    case 'pets':
      return <Pets width={width} height={height} fill={fill} stroke={stroke} />;
    case 'rabbit':
      return <Rabbit width={width} height={height} fill={fill} stroke={stroke} />;
    case 'turtle':
      return <Turtle width={width} height={height} fill={fill} stroke={stroke} />;
    case 'bull':
      return <Bull width={width} height={height} fill={fill} stroke={stroke} />;
    case 'elephant':
      return <Elephant width={width} height={height} fill={fill} stroke={stroke} />;
    case 'buffalo':
      return <Buffalo width={width} height={height} fill={fill} stroke={stroke} />;
    case 'rat':
      return <Rat width={width} height={height} fill={fill} stroke={stroke} />;
    case 'sheep':
      return <Sheep width={width} height={height} fill={fill} stroke={stroke} />;

    default:
      return <Layer width={width} height={height} fill={fill} stroke={stroke} />;
  }
};
