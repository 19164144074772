import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoutes from '../components/auth_component/ProtectedRoutes';
import Loader from '../components/loader_component/loader';
import { LoaderWrapper } from '../components/styled_components';

const LazyPets = React.lazy(() => import('../pages/pets'));
const LazyPetDetails = React.lazy(() => import('../pages/pets/pet_details'));
const LazyPetOwner = React.lazy(() => import('../pages/pet_owners'));
const LazyPetOwnerDetails = React.lazy(() => import('../pages/pet_owners/pet_owner_detail'));
const LazyVisitDetails = React.lazy(() => import('../pages/visits'));
const LazyPrescriptionTemplates = React.lazy(() => import('../pages/prescription/prescription_templates'));
const LazyLetterPadTemplates = React.lazy(() => import('../pages/letter_pad'));
const LazyBillPadTemplates = React.lazy(() => import('../pages/bill_pad'));
const LazyAnimal = React.lazy(() => import('../pages/masters/animal'));
const LazyPrescriptionTemplate = React.lazy(() => import('../pages/prescription/prescription_template'));
const LazyLetterPadTemplate = React.lazy(() => import('../pages/letter_pad/add_template'));
const LazyBillPadTemplate = React.lazy(() => import('../pages/bill_pad/add_template'));
const LazyBreed = React.lazy(() => import('../pages/masters/breed'));
const LazyColor = React.lazy(() => import('../pages/masters/color'));
const LazyVisitType = React.lazy(() => import('../pages/masters/visit_type'));
const LazyVisitPurpose = React.lazy(() => import('../pages/masters/visit_purpose'));
const LazySpaService = React.lazy(() => import('../pages/masters/spa_services'));
const LazyDisease = React.lazy(() => import('../pages/masters/disease'));
const LazySymptom = React.lazy(() => import('../pages/masters/symptom'));
const LazyMedicine = React.lazy(() => import('../pages/masters/medicine'));
const LazyUser = React.lazy(() => import('../pages/user'));
const LazyClinics = React.lazy(() => import('../pages/clinics'));
const LazyProfile = React.lazy(() => import('../pages/profile'));
const LazyFileManager = React.lazy(() => import('../pages/FileManager/file_manager'));
const LazyAppointment = React.lazy(() => import('../pages/appointment'));
const LazyPageNotFound = React.lazy(() => import('../pages/page_not_found'));
const LazyUnauthorized = React.lazy(() => import('../pages/unauthorized'));
const LazyTaxClasses = React.lazy(() => import('../pages/billings/tax_classes'));
const LazyServices = React.lazy(() => import('../pages/billings/services'));
const LazyPurchase = React.lazy(() => import('../pages/billings/purchase'));
const LazyPaymentMode = React.lazy(() => import('../pages/billings/payment_modes'));
const LazyBillings = React.lazy(() => import('../pages/billings/billings'));
const LazyBillReturns = React.lazy(() => import('../pages/billings/bill_returns'));
const LazyVendors = React.lazy(() => import('../pages/billings/vendors'));
const LazyPurchaseReturn = React.lazy(() => import('../pages/billings/purchase_return'));

// Subscriptions route
const LazyStorageCredits = React.lazy(() => import('../pages/subscription/storage_credits'));
const LazyStorageCreditsPay = React.lazy(() => import('../pages/subscription/storage_credits_payment'));
const LazyWhatsappCreditsPay = React.lazy(() => import('../pages/subscription/whatsapp_credits_payment'));
const LazyWhatsappCredits = React.lazy(() => import('../pages/subscription/whatsapp_credits'));
const LazyActivation = React.lazy(() => import('../pages/subscription/activation'));
const LazyOrderConfirm = React.lazy(() => import('../pages/subscription/order_confirm'));
const LazyPayment = React.lazy(() => import('../pages/subscription/payment'));
const LazyPricing = React.lazy(() => import('../pages/subscription/pricing'));
const LazyInvoice = React.lazy(() => import('../pages/subscription/invoice'));
const LazyBillngProfile = React.lazy(() => import('../pages/subscription/billing_profile'));
const LazyManualRetry = React.lazy(() => import('../pages/subscription/manual_retry'));

const UserRoutes = () => {
  return (
    <>
      <Route element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          exact
          path='/'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPets />
            </React.Suspense>
          }
        />
      </Route>
      <Route path='pet-owners' element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          path='list'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPetOwner />
            </React.Suspense>
          }
        />
        <Route element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
          <Route
            path='details'
            element={
              <React.Suspense
                fallback={
                  <LoaderWrapper>
                    <Loader color='#00BFFF' />
                  </LoaderWrapper>
                }
              >
                <LazyPetOwnerDetails />
              </React.Suspense>
            }
          />
        </Route>
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          path='visits'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyVisitDetails />
            </React.Suspense>
          }
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          path='appointment'
          element={
            <React.Suspense
              fallback={<Loader type='Bars' secondaryColor='gray' color='#00BFFF' height={50} width={50} />}
            >
              <LazyAppointment />
            </React.Suspense>
          }
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          path='pet-details'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPetDetails />
            </React.Suspense>
          }
        />
      </Route>
      <Route path='billing' element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          index
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyBillings />
            </React.Suspense>
          }
        />
        <Route
          path='bill-returns'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyBillReturns />
            </React.Suspense>
          }
        />
        <Route
          path='tax-classes'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyTaxClasses />
            </React.Suspense>
          }
        />
        <Route
          path='vendors'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyVendors />
            </React.Suspense>
          }
        />
        <Route
          path='services'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyServices />
            </React.Suspense>
          }
        />
        <Route
          path='purchases'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPurchase />
            </React.Suspense>
          }
        />
        <Route
          path='purchase-returns'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPurchaseReturn />
            </React.Suspense>
          }
        />
        <Route
          path='payment-modes'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPaymentMode />
            </React.Suspense>
          }
        />
      </Route>
      <Route path='template' element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor']} />}>
        <Route
          path='prescription-templates'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPrescriptionTemplates />
            </React.Suspense>
          }
        />
        <Route
          path='prescription-template'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPrescriptionTemplate />
            </React.Suspense>
          }
        />
        <Route
          path='letterpad'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyLetterPadTemplates />
            </React.Suspense>
          }
        />
        <Route
          path='letterpad-template'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyLetterPadTemplate />
            </React.Suspense>
          }
        />
        <Route
          path='billpad'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyBillPadTemplates />
            </React.Suspense>
          }
        />
        <Route
          path='billpad-template'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyBillPadTemplate />
            </React.Suspense>
          }
        />
      </Route>

      <Route path='master' element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          path='animal'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyAnimal />
            </React.Suspense>
          }
        />
        <Route
          path='breed'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyBreed />
            </React.Suspense>
          }
        />
        <Route
          path='color'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyColor />
            </React.Suspense>
          }
        />
        <Route
          path='visit-type'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyVisitType />
            </React.Suspense>
          }
        />
        <Route
          path='visit-purpose'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyVisitPurpose />
            </React.Suspense>
          }
        />
        {/* <Route
          path='spa-services'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazySpaService />
            </React.Suspense>
          }
        /> */}
        <Route
          path='disease'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyDisease />
            </React.Suspense>
          }
        />
        <Route
          path='symptom'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazySymptom />
            </React.Suspense>
          }
        />
        {/* <Route
          path='medicine'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyMedicine />
            </React.Suspense>
          }
        /> */}
      </Route>

      <Route
        path='profile'
        element={
          <React.Suspense
            fallback={
              <LoaderWrapper>
                <Loader color='#00BFFF' />
              </LoaderWrapper>
            }
          >
            <LazyProfile />
          </React.Suspense>
        }
      />
      <Route path='my-clinic' element={<ProtectedRoutes allowedRoles={['admin_doctor', 'doctor', 'assistant']} />}>
        <Route
          path='clinics'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyClinics />
            </React.Suspense>
          }
        />
        <Route
          path='users'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyUser />
            </React.Suspense>
          }
        />

        {/* Subscriptions routes */}
        <Route
          path='billing-profile'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyBillngProfile />
            </React.Suspense>
          }
        />
        <Route
          path='pricing'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPricing />
            </React.Suspense>
          }
        />
        <Route
          path='payment'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyPayment />
            </React.Suspense>
          }
        />
        <Route
          path='retry-payment'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyManualRetry />
            </React.Suspense>
          }
        />
        <Route
          path='order_confirm'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyOrderConfirm />
            </React.Suspense>
          }
        />
        <Route
          path='activation'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyActivation />
            </React.Suspense>
          }
        />
        <Route
          path='wacredits'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyWhatsappCredits />
            </React.Suspense>
          }
        />
        <Route
          path='wacredits_pay'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyWhatsappCreditsPay />
            </React.Suspense>
          }
        />
        <Route
          path='sgcredits'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyStorageCredits />
            </React.Suspense>
          }
        />
        <Route
          path='sgcredits_pay'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyStorageCreditsPay />
            </React.Suspense>
          }
        />
        {/* Subscriptions routes ends */}
        <Route
          exact
          path='invoice'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyInvoice />
            </React.Suspense>
          }
        />
        <Route
          path='storage'
          element={
            <React.Suspense
              fallback={
                <LoaderWrapper>
                  <Loader color='#00BFFF' />
                </LoaderWrapper>
              }
            >
              <LazyFileManager />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path='*'
        element={
          <React.Suspense
            fallback={
              <LoaderWrapper>
                <Loader color='#00BFFF' />
              </LoaderWrapper>
            }
          >
            <LazyPageNotFound />
          </React.Suspense>
        }
      />
      <Route
        exact
        path='unauthorized'
        element={
          <React.Suspense
            fallback={
              <LoaderWrapper>
                <Loader color='#00BFFF' />
              </LoaderWrapper>
            }
          >
            <LazyUnauthorized />
          </React.Suspense>
        }
      />
    </>
  );
};

export default UserRoutes;
