import axios from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAuth } from '../components/auth_component';

export const SubscriptionContext = createContext(null);

const SubscriptionProvider = ({ children }) => {
  const auth = useAuth();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const getSubscriptionData = useCallback(async () => {
    if (auth?.user) {
      await axios
        .get(`subscription/${auth.user.clinic.id}`)
        .then((res) => {
          setSubscriptionDetails({ ...res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [auth?.user]);

  useEffect(() => {
    getSubscriptionData();
    // const dataInterval = setInterval(getSubscriptionData, 5 * 60 * 1000);
    // return () => clearInterval(dataInterval);
  }, [getSubscriptionData]);

  return (
    <SubscriptionContext.Provider value={{ subscriptionDetails, getSubscriptionData }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};
