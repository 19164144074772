import React from 'react';
import { Navigate, Routes } from 'react-router-dom';

/**
 * Custom created component files
 */
import SideNavBar from '../../components/navbar_component/SideNavBar';
import TopNavBar from '../../components/navbar_component/TopNavBar';
import { AppContentArea, ContentArea, MainContentArea, TopBarArea } from './Layout.style';
import { useAuth } from '../../components/auth_component';
import Loader from '../../components/loader_component/loader';
import { LoaderWrapper } from '../../components/styled_components';
import { RootRoutes } from '../../routes/RootRoutes';
// import CheckSubscription from '../../components/subscription_components/check_subscription';

export default function Layout() {
  const auth = useAuth();

  return auth.user && !auth.isLoggedIn ? (
    <AppContentArea className='app-content-area'>
      <SideNavBar />
      <ContentArea className='content' id='content-wrapper-area' style={{ position: 'relative' }}>
        <TopBarArea className='top-nav-bar'>
          <TopNavBar userName={auth?.user?.name} />
        </TopBarArea>
        <MainContentArea className='main-content'>
          {/* <Outlet /> */}
          <Routes>{RootRoutes(auth)}</Routes>
        </MainContentArea>
        {/* <CheckSubscription /> */}
      </ContentArea>
    </AppContentArea>
  ) : auth.isLoggedIn && !localStorage.getItem('token') ? (
    <Navigate to={'/login'} />
  ) : (
    <LoaderWrapper>
      <Loader color='#00BFFF' />
    </LoaderWrapper>
  );
}
