import React from 'react';
import moment from 'moment';
import { useSubscription } from '../../../context/SubscriptionContext';
import { NavLink } from 'react-router-dom';
import { Container } from './styles';
import { commonData } from '../../../locale/en/CommonData';
import { formatDateWithSuperscript } from '../../../utils/GeneralFunctions';

function Notification() {
  const { subscriptionDetails } = useSubscription();

  return (
    <Container>
      {subscriptionDetails &&
        (subscriptionDetails?.failed_payment ? (
          <div>
            {`Your last payment was not success.`}
            <NavLink
              to={
                moment().format(commonData.date_format) >
                  moment(subscriptionDetails?.expiry_date).add(7, 'days').format(commonData.date_format) &&
                moment().format(commonData.date_format) <=
                  moment(subscriptionDetails?.grace_period).format(commonData.date_format)
                  ? '/my-clinic/pricing'
                  : '/my-clinic/billing-profile'
              }
            >
              <b>
                &nbsp;
                {moment().format(commonData.date_format) >
                  moment(subscriptionDetails?.expiry_date).add(7, 'days').format(commonData.date_format) &&
                moment().format(commonData.date_format) <=
                  moment(subscriptionDetails?.grace_period).format(commonData.date_format)
                  ? 'Pay Now.'
                  : 'Fix it.'}
              </b>
            </NavLink>
          </div>
        ) : subscriptionDetails?.in_trial_period === 1 &&
          moment(subscriptionDetails?.expiry_date).format(commonData.date_format) >=
            moment().format(commonData.date_format) ? (
          <div>
            Your trial expires on {formatDateWithSuperscript(subscriptionDetails?.expiry_date)}.
            <NavLink to='/my-clinic/pricing'>
              <b>&nbsp;Subscribe Now</b>
            </NavLink>
          </div>
        ) : moment(subscriptionDetails?.expiry_date).format(commonData.date_format) >=
            moment().format(commonData.date_format) &&
          moment(subscriptionDetails?.expiry_date).format(commonData.date_format) <=
            moment().add(5, 'days').format(commonData.date_format) &&
          subscriptionDetails?.in_trial_period !== 1 &&
          subscriptionDetails?.subscription_status != 'canceled' &&
          subscriptionDetails?.user_cancelled !== 1 ? (
          <div>
            Your subscription will be renewed on {formatDateWithSuperscript(subscriptionDetails?.expiry_date)}.
            {/*           <NavLink to='/my-clinic/pricing'>
            <b>&nbsp;Subscribe Now</b>
          </NavLink> */}
          </div>
        ) : subscriptionDetails?.in_trial_period === 1 &&
          moment().format(commonData.date_format) >
            moment(subscriptionDetails?.expiry_date).format(commonData.date_format) ? (
          <div>
            Your trial period expired on {formatDateWithSuperscript(subscriptionDetails?.expiry_date)}.
            <NavLink to='/my-clinic/pricing'>
              <b>&nbsp;Subscribe Now</b>
            </NavLink>
          </div>
        ) : (
          moment().format(commonData.date_format) >
            moment(subscriptionDetails?.expiry_date).format(commonData.date_format) && (
            <div>
              Your subscription expired on {formatDateWithSuperscript(subscriptionDetails?.expiry_date)}.
              <NavLink to='/my-clinic/pricing'>
                <b>&nbsp;Subscribe Now</b>
              </NavLink>
            </div>
          )
        ))}
    </Container>
  );
}

export default Notification;
