import axios from 'axios';
import UserRoutes from './UserRoutes';
import swal from 'sweetalert';

export const RootRoutes = (auth) => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error) => {
      if (error && error.response && error.response.status === 401) {
        await swal({
          title: 'Expired!!',
          text: 'Your session has expired.',
          icon: 'error',
          closeOnClickOutside: false,
        });

        auth.resetUser();
      }
      return Promise.reject(error);
    }
  );

  return auth && !auth.isLoggedIn && auth.user && auth.user.role !== 'sa' ? UserRoutes() : null;
};
