/**
 * Import third party components
 */
import { NavLink, useNavigate } from 'react-router-dom';

/**
 * Custom created component files
 */
import { useState } from 'react';
import {
  MenuList,
  MenuSpan,
  MenuUL,
  Nav,
  NavIcon,
  NavList,
  NavUL,
  PageTitle,
  UserIcon,
  UserIconWrapper,
  UserInfoTileWrapper,
  UserName,
} from './TopNavBarStyle';
import { GetUserIconLT } from '../../../utils/GeneralFunctions';
import { useAuth } from '../../auth_component';
import { Icons } from '../../../assets/Icons';
import Notification from '../notification';

export default function TopNavBar({ title, userName = 'User' }) {
  const [_toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const toggleMenu = () => {
    let toggle = !_toggle;
    setToggle(toggle);

    if (toggle) {
      setTimeout(() => {
        setToggle(false);
      }, 5000);
    }
  };

  const menu = (
    <MenuUL toggle={_toggle}>
      <MenuList key='menu_0'>
        <NavLink to='/profile'>
          <MenuSpan>
            <NavIcon name='NavUser' width={15} height={15} />
          </MenuSpan>
          <MenuSpan>My Account</MenuSpan>
        </NavLink>
      </MenuList>
      <MenuList key='menu_1'>
        <a href={`${process.env.REACT_APP_SITE_URL}/support`} target='_blank' rel='noreferrer'>
          <MenuSpan>
            <NavIcon name='Support' width={15} height={22} />
          </MenuSpan>
          <MenuSpan>Support</MenuSpan>
        </a>
      </MenuList>
      <MenuList key='menu_2'>
        <NavLink
          to='#'
          onClick={() => {
            toggleMenu();
            auth.logout();
          }}
        >
          <MenuSpan>
            <NavIcon name='LogOut' width={15} height={15} />
          </MenuSpan>
          <MenuSpan>Logout</MenuSpan>
        </NavLink>
      </MenuList>
    </MenuUL>
  );

  return (
    <Nav>
      <NavUL>
        <NavList className='title-wrapper'>
          {auth?.currentPage.backRoute && (
            <button className='back-btn' onClick={() => navigate(auth?.currentPage.backRoute)}>
              <Icons name={'Back'} width={40} height={40} />
            </button>
          )}
          <PageTitle>{auth?.currentPage.title}</PageTitle>
        </NavList>
        <NavList>
          <Notification />
        </NavList>
        <NavList>
          <UserInfoTileWrapper onClick={toggleMenu}>
            <UserIconWrapper>
              <UserIcon>{GetUserIconLT(userName)}</UserIcon>
            </UserIconWrapper>
            <UserName>{userName}</UserName>
            <div style={{ marginLeft: '5px' }}>
              <NavIcon name='DropArrow' width={15} height={15} fill='#839aa8' />
            </div>
          </UserInfoTileWrapper>
          {menu}
        </NavList>
      </NavUL>
    </Nav>
  );
}
