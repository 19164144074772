import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/auth_component';
import Layout from './pages/layout';
import Loader from './components/loader_component/loader';
import { LoaderWrapper } from './components/styled_components';
import SubscriptionProvider from './context/SubscriptionContext';
import DashboardProvider from './context/DashboardContext';

const LazyPageNotFound = React.lazy(() => import('./pages/page_not_found'));
const LazyLogin = React.lazy(() => import('./pages/login'));
const LazyPasswordReset = React.lazy(() => import('./pages/password_reset'));
const LazyForcePassword = React.lazy(() => import('./pages/force_password'));
const LazyFiles = React.lazy(() => import('./pages/client_filemanager/file_manager_client'));

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <DashboardProvider>
          <SubscriptionProvider>
            <Routes>
              <Route path='*' element={<Layout />}>
                <Route
                  path='*'
                  element={
                    <React.Suspense
                      fallback={
                        <LoaderWrapper>
                          <Loader color='#00BFFF' />
                        </LoaderWrapper>
                      }
                    >
                      <LazyPageNotFound />
                    </React.Suspense>
                  }
                />
              </Route>
              <Route
                exact
                path='login'
                element={
                  <React.Suspense
                    fallback={
                      <LoaderWrapper>
                        <Loader color='#00BFFF' />
                      </LoaderWrapper>
                    }
                  >
                    <LazyLogin />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='reset-password'
                element={
                  <React.Suspense
                    fallback={
                      <LoaderWrapper>
                        <Loader color='#00BFFF' />
                      </LoaderWrapper>
                    }
                  >
                    <LazyPasswordReset />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='force-password'
                element={
                  <React.Suspense
                    fallback={
                      <LoaderWrapper>
                        <Loader color='#00BFFF' />
                      </LoaderWrapper>
                    }
                  >
                    <LazyForcePassword />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='files'
                element={
                  <React.Suspense
                    fallback={
                      <LoaderWrapper>
                        <Loader color='#00BFFF' />
                      </LoaderWrapper>
                    }
                  >
                    <LazyFiles />
                  </React.Suspense>
                }
              />
            </Routes>
          </SubscriptionProvider>
        </DashboardProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
