import { NavLink } from 'react-router-dom';

/**
 * Import third party components
 */
import styled from 'styled-components';

/**
 * Custom created component files
 */
import { GNav, GNavUL, GNavList } from '../../../styled_components/';
import { NavIcons } from '../../../../assets/NavIcons';

export const SideBar = styled.div`
  height: auto;

  &.toggled + div {
    margin-left: 0;
  }
`;
export const NavContentArea = styled.div`
  width: 240px;
  background-color: var(--bg-dark-grey-color);
  left: 0px;
  height: 100%;
  transition: left 0.2s ease-in-out;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  overflow: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    border: 1px solid black;
  }
  &::-webkit-scrollbar-track {
    background: #2e353d;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #808489;
  }

  &.toggled {
    left: -240px;
    display: none;
  }
`;
export const Nav = styled(GNav)``;
export const NavUL = styled(GNavUL)``;
export const NavList = styled(GNavList)`
  padding: 0;
`;
export const NavHeadingContainer = styled.div`
  border-bottom: 2px solid #757c83;
`;
export const RouteLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 14px 27px;
  color: var(--bg-dark-grey-shade3-color);

  &.selected {
    color: var(--white-color);
  }

  &.selected::before {
    content: '';
    width: 5px;
    height: 26px;
    background-color: var(--border-light-blue-color);
    position: absolute;
    left: 0;
  }
  &:hover {
    color: var(--white-color);
  }
  &.selected svg path {
    fill: var(--white-color);
  }
  &:hover svg path {
    fill: var(--white-color);
  }
`;
export const NavIcon = styled(NavIcons)`
  vertical-align: bottom;
`;
export const NavListTitle = styled.span`
  margin-left: 7px;
`;

export const SubMenu = styled.div`
  padding: 0;
`;
export const SubMenuTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 27px;
  color: var(--bg-dark-grey-shade3-color);
  cursor: pointer;

  &.active {
    color: var(--white-color);
  }

  &.active::before {
    content: '';
    width: 5px;
    height: 26px;
    background-color: var(--border-light-blue-color);
    position: absolute;
    left: 0;
  }
  &:hover {
    color: var(--white-color);
  }
  /* &.arrow-down svg {
    transform: rotate(180deg);
  } */
  &.active svg path {
    fill: var(--white-color);
  }
  &:hover svg path {
    fill: var(--white-color);
  }
`;
export const SubMenuList = styled.div`
  padding-left: 10px;
  display: none;

  &.toggled {
    display: block;
  }
`;
export const SubRouteLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 8px 27px;
  color: var(--bg-dark-grey-shade3-color);

  &.selected {
    color: var(--white-color);
  }

  &.selected::before {
    content: '';
    width: 5px;
    height: 26px;
    background-color: var(--border-light-blue-color);
    position: absolute;
    left: 0;
  }
  &:hover {
    color: var(--white-color);
  }
  &.selected svg path {
    fill: var(--white-color);
  }
  &:hover svg path {
    fill: var(--white-color);
  }
`;
export const NavCloseBG = styled.span`
  display: ${(props) => (props.resized && !props.toggle ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 997;
  // background-color: #39434d70;
  background-color: rgba(0, 0, 0, 0.5);

  &.toggled {
    display: none;
  }
`;
export const NavFooterContainer = styled.div`
  margin-top: auto;
  padding: 1em;
`;
export const ProgressContainer = styled.div`
  background-color: var(--bg-dark-grey-shade2-color);
  margin-top: 1em;
  border-radius: 10px;
  padding: 1em 1em 0.5em;
  color: var(--white-color);
  // cursor: pointer;

  &.whatsapp-credit {
    display: flex;
    gap: 8px;
  }
`;
export const ProgressHeader = styled.h1`
  font-size: 14px;
  font-weight: bold;
  color: var(--white-color);
  margin: 0;
`;
export const ProgressLimit = styled.div`
  font-size: 12px;
  font-weight: regular;
  color: var(--white-color);
  display: flex;
  justify-content: space-between;
  padding: 0.3em 0.1em 0.2em;
`;
export const ProgressStartLimit = styled.span``;
export const ProgressEndLimit = styled.span``;
export const ProgressBar = styled.div`
  width: 100%;
  background-color: ${(props) => (props.usagepercent >= 90 ? 'var(--bg-dark-red-color)' : '#e8e8e8')};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.05em;
  position: relative;
  overflow: hidden;

  & div {
    background-color: ${(props) => (props.usagepercent >= 90 ? 'var(--bg-dark-red-color)' : '#6ea6dd')};
  }

  & span {
    color: ${(props) => (props.usagepercent >= 90 ? 'var(--white-color)' : 'var(--bg-dark-grey-color)')};
  }
`;
export const ProgressSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6ea6dd;
  height: 100%;
  width: ${(props) => props.value};
  z-index: 8;
  transition: var(--bs-progress-bar-transition);
`;
export const ProgressValue = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: var(--bg-dark-grey-color);
  z-index: 10;
`;
export const ProgressBalance = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 0.3em;
  color: var(--white-color);
`;
